import React from 'react'
import cn from 'classnames'

import styles from './styles.module.scss'

type Props = {
  image: React.ReactNode
  title: string
  text: string
  className?: string
  style?: React.CSSProperties
}

export const Tile: React.FC<Props> = ({ image, title, text, className, style }) => {
  return (
    <div className={cn(styles.container, className)} style={style}>
      <div className={styles.img}>{image}</div>
      <div className={styles['text-container']}>
        <b className={styles.title}>{title}</b>
        <p className={styles.text}>{text}</p>
      </div>
    </div>
  )
}
