import React from 'react'

import { Card } from '../../../components/Card'
import { TextGroup } from '../../../components/TextGroup'
import { Tile } from '../../../components/Tile'
import { Title } from '../../../components/Title'
import { VariantsContainer } from '../../../components/VariantsContainer'

import { Canvas } from '../components/Canvas'

import { CopyAndPaste } from '..'

import { density, dingbatsStar, geometricStarShapes, height, mathStars, pentagrams, religiousStars, starEmojis, tags, width } from './constants'

import { generateStarfield } from './functions'

import religiousStarImg from './assets/religious-star.webp'
import sparkleImg from './assets/sparkle.webp'
import starsImg from './assets/stars.webp'

import styles from './styles.module.scss'
import { snowflakeSymbolsTool } from '../../tools'

const generator = (): string => generateStarfield(height, width, density)

const StarSymbols: React.FC = () => {
  return (
    <CopyAndPaste tags={tags} moreTools={[snowflakeSymbolsTool]} subtitleClassName={styles.subtitle}>
      <TextGroup
        title='Classic Dingbats stars'
        biggerText
      >
        <p>
          ✰
        </p>
        <p>
          This set of stars was designed in 1977 by Hermann Zapf as part of a set of over 1,000 symbols called Zapf Dingbats.
          If you were using computers in the ‘90s, you might remember the Windows equivalent: Wingdings.
        </p>
      </TextGroup>
      <VariantsContainer className={styles['variants-container']}>
        {dingbatsStar.map(variant => <Card className={styles.card} key={variant.name} title={variant.name} text={variant.text} textFontSize={32} textAlignCenter />)}
      </VariantsContainer>
      <Tile
        title='Chicago flag stars'
        text='The Chicago flag contains four six-pointed stars – one for each of the four great events that shaped the city.'
        image={<img src={starsImg} />}
        style={{ marginTop: 128 }}
        className={styles.tile}
      />
      <Canvas title='Starfield generator' subtitle='Click the button below to generate a Unicode star field.' generator={generator} buttonText='Shuffle stars' />
      <Title size={3} className={styles['secondary-title']}>Star emojis</Title>
      <VariantsContainer className={styles['variants-container']}>
        {starEmojis.map(variant => <Card className={styles.card} key={variant.name} title={variant.name} text={variant.text} textFontSize={32} textAlignCenter />)}
      </VariantsContainer>
      <Tile
        title='The dizzy symbol'
        text="The dizzy symbol is often mistaken for a shooting star. It originates from comics and cartoons, where stars swirling around a character's head denote confusion or drunkenness."
        image={<img src={sparkleImg} />}
        style={{ marginTop: 64 }}
        className={styles.tile}
      />
      <Title size={5} className={styles['secondary-title']}>Pentagrams</Title>
      <p className={styles.text}>
        A pentagram or pentacle is a five-pointed star drawn with a single continuous line. Pentagrams mean different things to different people, but they’re closely associated with mysticism and religion.
      </p>
      <p className={styles.text}>
        Note: Pentagrams don't render correctly on all devices.
      </p>
      <VariantsContainer className={styles['variants-container']}>
        {pentagrams.map(variant => <Card className={styles.card} key={variant.name} title={variant.name} text={variant.text} textFontSize={32} textAlignCenter />)}
      </VariantsContainer>
      <Title size={3} className={styles['secondary-title']}>Religious stars ✡</Title>
      <p className={styles.text}>
        Many religions and sects use stars in their symbols. Examples include the Star of David and the star and crescent moon.
      </p>
      <VariantsContainer className={styles['variants-container']}>
        {religiousStars.map(variant => <Card className={styles.card} key={variant.name} title={variant.name} text={variant.text} textFontSize={32} textAlignCenter />)}
      </VariantsContainer>
      <Tile
        title='The star and crescent moon'
        text="The star and crescent moon is most well known as the symbol of Islam, but it dates back all the way to ancient Egypt and probably before."
        image={<img src={religiousStarImg} />}
        style={{ marginTop: 64 }}
        className={styles.tile}
      />
      <Title size={3} className={styles['secondary-title']}>More geometric star shapes 🟀</Title>
      <p className={styles.text}>
        Similar to the stars from Zapf Dingbats or Webdings, these characters were added to Unicode to extend the number of available stars. Included are options for different weights and the number of points, such as three-pointed and twelve-pointed stars.
      </p>
      <p className={styles.text}>
        Note: These shapes don't render correctly on all devices.
      </p>
      <VariantsContainer className={styles['variants-container']}>
        {geometricStarShapes.map(variant => <Card className={styles.card} key={variant.name} title={variant.name} text={variant.text} textFontSize={32} textAlignCenter />)}
      </VariantsContainer>
      <Title size={3} className={styles['secondary-title']}>Stars for math and coding 🟀</Title>
      <VariantsContainer className={styles['variants-container']}>
        {mathStars.map(variant => <Card className={styles.card} key={variant.name} title={variant.name} text={variant.text} textFontSize={32} textAlignCenter />)}
      </VariantsContainer>
      <Title size={3} className={styles['secondary-title']}>Half star symbols ⯨</Title>
      <p className={styles.text}>
        Unicode has designated code points for half stars, which can be used for ratings and other applications. However, there are very few situations where these half stars will render properly.
      </p>
      <VariantsContainer className={styles['variants-container']}>
        {geometricStarShapes.map(variant => <Card className={styles.card} key={variant.name} title={variant.name} text={variant.text} textFontSize={32} textAlignCenter />)}
      </VariantsContainer>
      <Title size={3} className={styles['secondary-title']}>Other stars ⯨</Title>
      <p className={styles.text}>
        There are over 130,000 Unicode characters, so it's not surprising that a lot of them resemble stars. Additional star-shaped characters are featured below.
      </p>
      <VariantsContainer className={styles['variants-container']}>
        {geometricStarShapes.map(variant => <Card className={styles.card} key={variant.name} title={variant.name} text={variant.text} textFontSize={32} textAlignCenter />)}
      </VariantsContainer>
    </CopyAndPaste>
  )
}

export default StarSymbols
