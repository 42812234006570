import React, { useEffect, useState } from 'react'

import { Title } from '../../../../components/Title'
import { Button } from '../../../../components/Button'
import { Tooltip } from '../../../../components/Tooltip'

import CopyIcon from '../../../../assets/images/copy-icon.svg'

import styles from './styles.module.scss'

type Props = {
  title: string
  subtitle: string
  generator: () => string
  buttonText: string
}

export const Canvas: React.FC<Props> = ({ title, subtitle, generator, buttonText }) => {
  const copyToClipboard = (e: React.SyntheticEvent): void => {
    e.preventDefault()
    navigator.clipboard.writeText(canvasText) as any
  }

  const [canvasText, setCanvasText] = useState('')

  const randomize = (): void => setCanvasText(generator())

  useEffect(() => {
    setCanvasText(generator())
  }, [])

  return (
    <div className={styles.container}>
      <Title className={styles['secondary-title']} size={3} center>{title}</Title>
      <p className={styles.text}>{subtitle}</p>
      <pre className={styles.canvas}>
        <Tooltip text='Copied to clipboard!' showOnClick>
          <div className={styles.copy}>
            <Tooltip text='Copy to clipboard'>
              <CopyIcon className={styles['copy-icon']} onClick={copyToClipboard} />
            </Tooltip>
          </div>
        </Tooltip>
        {canvasText}
      </pre>
      <Button onClick={randomize}>{buttonText}</Button>
    </div>
  )
}
