export const dingbatsStar = [
  { name: 'Black star', text: '★' },
  { name: 'White star', text: '☆' },
  { name: 'Circled white star', text: '✪' },
  { name: 'Open centre black star', text: '✫' },
  { name: 'Black four pointed star', text: '✦' },
  { name: 'White four pointed star', text: '✧' },
  { name: 'Black centre white star', text: '✬' },
  { name: 'Outlined black star ', text: '✭' },
  { name: 'Heavy outlined black star ', text: '✮' },
  { name: 'Pinwheel star', text: '✯' },
  { name: 'Shadowed white star', text: '✰' },
  { name: 'Eight pointed blackstar', text: '✴' },
  { name: 'Eight pointed pinwheel star ', text: '✵' },
  { name: 'Six pointed black star', text: '✶' },
  { name: 'Eight pointed rectilinear black star', text: '✷' },
  { name: 'Heavy eight pointed rectilinear black star', text: '✸' },
  { name: 'Twelve pointed black star', text: '✹' }
]

export const starEmojis = [
  { name: 'Star emoji', text: '⭐' },
  { name: 'Glowing star', text: '🌟' },
  { name: 'Shooting star', text: '🌠' },
  { name: 'Sparkles', text: '✨' },
  { name: 'Dizzy', text: '💫' },
  { name: 'Night with stars', text: '🌃' },
  { name: 'Milky Way', text: '🌌' },
  { name: 'Starstruck', text: '🤩' }
]

export const pentagrams = [
  { name: 'Pentagram', text: '⛤' },
  { name: 'Right-handed interlaced pentagram', text: '⛥' },
  { name: 'Left-handed interlaced pentagram', text: '⛦' },
  { name: 'Inverted pentagram', text: '⛧' }
]

export const religiousStars = [
  { name: 'Star of David', text: '✡️' },
  { name: 'Dotted six pointed star', text: '🔯' },
  { name: 'Star and crescent', text: '☪️' },
  { name: 'Egyptian hieroglyph star and crescent', text: '𓇻' }
]

export const geometricStarShapes = [
  { name: 'Light three pointed black star', text: '🟀' },
  { name: 'Medium three pointed black star', text: '🟁' },
  { name: 'Three pointed black star ', text: '🟂' },
  { name: 'Three pointed pinwheel star ', text: '🟃' },
  { name: 'Light four pointed black star', text: '🟄' },
  { name: 'Medium four pointed black star ', text: '🟅' },
  { name: 'Four pointed black star', text: '🟆' },
  { name: 'Medium four pointed pinwheel star', text: '🟇' },
  { name: 'Reverse light four pointed pinwheel star', text: '🟈' },
  { name: 'Light five pointed black star', text: '🟉' },
  { name: 'Heavy five pointed black star', text: '🟊' },
  { name: 'Medium six pointed black star', text: '🟋' },
  { name: 'Heavy six pointed black star', text: '🟌' },
  { name: 'Six pointed pinwheel star', text: '🟍' },
  { name: 'Medium eight pointed black star', text: '🟎' },
  { name: 'Heavy eight pointed black star', text: '🟏' },
  { name: 'Very heavy eight pointed black star', text: '🟐' },
  { name: 'Heavy eight pointed pinwheel star', text: '🟑' },
  { name: 'Light twelve pointed black star', text: '🟒' },
  { name: 'Heavy twelve pointed black star', text: '🟓' },
  { name: 'Heavy twelve pointed pinwheel star', text: '🟔' }
]

export const mathStars = [
  { name: 'Star operator', text: '⋆' },
  { name: 'Star equals', text: '≛' },
  { name: 'APL circle star', text: '⍟' },
  { name: 'APL star with diaeresis', text: '⍣' }
]

export const halfStars = [
  { name: 'Left half black star', text: '⯨' },
  { name: 'Right half black star', text: '⯩' },
  { name: 'Star with left half black', text: '⯪' },
  { name: 'Star with right half black', text: '⯫' }
]

export const otherStars = [
  { name: 'Egyptian hieroglyph star', text: '𓇼' },
  { name: 'Egyptian hieroglyph circled star', text: '𓇽' }
]

export const tags = [
  { name: 'Unicode' },
  { name: 'Tools' },
  { name: 'Meta' },
  { name: 'Symbols' },
  { name: 'Emoji' },
  { name: 'Copy and paste' },
  { name: 'Memes' },
  { name: 'Dingbats' },
  { name: 'Stars' }
]

const stars = ['.', '*', '°', '✦', '★', '☆', '✧', '✵', '✶', '✯', '✷', '✸']
const dots = ['.', '*', '°', '·', '•']
export const density = 10
export const height = 20
export const width = 40
export const shapes: Record<'stars' | 'dots', string[]> = {
  stars,
  dots
}
